<template>
    <defaultSec class="group-manage-detail-wrap" :title="'< 返回'" :returnState="true">
        <div class="section-title"><i class="el-icon-s-custom"></i>群组信息</div>
        <section class="section-content">
            <el-form  size="mini" label-width="120px">
                <el-form-item label="群组名称" :required="true">
                    <el-input v-model="groupName" placeholder="名称"></el-input>
                </el-form-item>
                <el-form-item label="备注" :required="true">
                    <el-input v-model="remark" placeholder="备注"></el-input>
                </el-form-item>
            </el-form>
        </section>
        <div class="section-title"><i class="el-icon-s-management"></i>分组规则</div>
        <section class="section-content">
            <section class="filter-wrap">
                <div class="filter-title">
                    <p>用户属性满足</p>
                    <el-button type="text" size="mini" @click="addNewUserProperty">+添加新的属性</el-button>
                </div>
                <div class="filter-content">
                    <div class="mid-mid line-wrap" v-if="userPropertyList.length>1"><p>且</p></div>
                    <div class="mid-right">
                        <div class="mid-right-item filter-item-mb" v-for="(item,index) in userPropertyList">
                            <el-select @change="changeProperty(item.selUserPropertyIndex,index)" class="filter-item-button filter-item-mr" v-model="item.selUserPropertyIndex" size="mini" placeholder="请选择">
                                <el-option v-for="(item1,index1) in userOptionList" :label="item1.propertyName" :value="index1"></el-option>
                            </el-select>
                            <el-select @change="timeUserChange(index)" style="width: 100px" v-if="userOptionList[item.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1" class="filter-item-button filter-item-mr" v-model="item.selUserPropertyTimeIndex" size="mini" placeholder="请选择">
                                <el-option v-for="(item1,index1) in userOptionList[item.selUserPropertyIndex||0].timeTypeItemList" :label="item1.name" :value="index1"></el-option>
                            </el-select>
                            <el-select v-if="userOptionList[item.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1" style="width: 110px" class="filter-item-button filter-item-mr" v-model="item.selUserPropertyOperateIndex" size="mini" placeholder="请选择">
                                <el-option v-for="(item1,index1) in userOptionList[item.selUserPropertyIndex||0].operateTypeItemList.filter(time=>(item.selUserPropertyTimeIndex+1)==time.timeTypeId)" :label="item1.operateName" :value="index1"></el-option>
                            </el-select>
                            <el-select v-else style="width: 110px" class="filter-item-button filter-item-mr" v-model="item.selUserPropertyOperateIndex" size="mini" placeholder="请选择">
                                <el-option v-for="(item1,index1) in userOptionList[item.selUserPropertyIndex||0].operateTypeItemList" :label="item1.operateName" :value="index1"></el-option>
                            </el-select>
                            <template v-if="userOptionList[item.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1">
                                <template v-if="item.selUserPropertyTimeIndex==0">
                                    <div style="display: inline-block" v-if="(userOptionList[item.selUserPropertyIndex||0].operateTypeItemList.filter(time=>(item.selUserPropertyTimeIndex+1)==time.timeTypeId))[item.selUserPropertyOperateIndex||0].moreParam==1">
                                        <el-input-number size="mini" v-model="item.selUserPropertyNumberValue1" controls-position="right" :min="0"></el-input-number>
                                        ~
                                        <el-input-number size="mini" v-model="item.selUserPropertyNumberValue2" controls-position="right" :min="0"></el-input-number>
                                    </div>
                                    <template v-else>
                                        <el-input-number size="mini" v-model="item.selUserPropertyNumberValue" controls-position="right" :min="0"></el-input-number>
                                    </template>
                                    <el-select style="width: 70px;margin-left: 10px" class="filter-item-button filter-item-mr" v-model="item.selUserPropertyOperateUnitValue" size="mini" placeholder="请选择">
                                        <el-option v-for="(item1,index1) in userOptionList[item.selUserPropertyIndex||0].timeTypeItemList[item.selUserPropertyTimeIndex].activeTimeItemList" :label="item1.name" :value="item1.value"></el-option>
                                    </el-select>
                                </template>
                                <template v-else>
                                    <el-date-picker
                                        v-if="(userOptionList[item.selUserPropertyIndex||0].operateTypeItemList.filter(time=>(item.selUserPropertyTimeIndex+1)==time.timeTypeId))[item.selUserPropertyOperateIndex||0].moreParam==1"
                                        size="mini"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.selUserPropertyTimeValue1"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                    <el-date-picker
                                        v-else
                                        size="mini"
                                        value-format="yyyy-MM-dd HH:mm:ss"
                                        v-model="item.selUserPropertyTimeValue"
                                        type="datetime"
                                        placeholder="选择日期">
                                    </el-date-picker>
                                </template>
                            </template>
                            <template v-else-if="userOptionList[item.selUserPropertyIndex].dicType==1">
                                <el-select class=" filter-item-mr" multiple filterable reserve-keyword v-model="item.selUserPropertyMulValue" size="mini" placeholder="可输入搜索">
                                    <el-option v-for="item1 in item.selUserPropertyValueList" :label="item1.dicValue" :value="item1.dicKey"></el-option>
                                </el-select>
                            </template>
                            <template v-else-if="userOptionList[item.selUserPropertyIndex||0].properytDataType=='Number'">
                                <template v-if="userOptionList[item.selUserPropertyIndex||0].operateTypeItemList[item.selUserPropertyOperateIndex].moreParam==1">
                                    <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item.selUserPropertyNumberValue1" size="mini" type="Number"></el-input>
                                    ~
                                    <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item.selUserPropertyNumberValue2" size="mini" type="Number"></el-input>
                                </template>
                                <el-input style="width: 100px" v-else class="value-input filter-item-mr" v-model="item.selUserPropertyNumberValue" size="mini" type="Number"></el-input>
                            </template>
                            <template v-else>
                                <el-input class="value-input filter-item-mr" v-model="item.selUserPropertyStringValue" size="mini" type="text"></el-input>
                            </template>
                            <el-button style="color: red" icon="el-icon-close" size="mini" type="text" @click="delUserProperty(index)"></el-button>
                        </div>
                    </div>
                </div>
            </section>
            <section class="filter-wrap">
                <div class="filter-title">
                    <p>用户行为满足</p>
                    <el-button type="text" size="mini" @click="addNewAction">+添加新的行为</el-button>
                </div>
                <div class="filter-content">
                    <div class="line-wrap" v-if="userActionList.length>1"><p>且</p></div>
                    <div class="content-right">
                        <div class="filter-item" v-for="(item,index) in userActionList">
                            <div class="filter-item-top filter-item-mb">
                                <span class="filter-index filter-item-mr">{{createCellPos(index)}}</span>
                                <el-select @change="changeEvent(index)" class="filter-item-mr" v-model="item.eventIndex" size="mini" placeholder="选择事件">
                                    <el-option v-for="(item1,index1) in eventOptionList" :label="item1.eventName" :value="index1"></el-option>
                                </el-select>
                                <el-button type="text" size="mini" @click="addNewActionProperty(index,item.eventIndex)">+添加筛选</el-button>
                                <el-button style="color: red" icon="el-icon-close" size="mini" type="text" @click="delEvent(index)"></el-button>
                            </div>
                            <div class="filter-item-mid filter-item-mb" v-if="item.filtersRuleList.length>0">
                                <div class="mid-left">并且满足</div>
                                <div class="mid-mid line-wrap" v-if="item.filtersRuleList.length>1"><p>且</p></div>
                                <div class="mid-right">
                                    <div class="mid-right-item filter-item-mb" v-for="(item1,index1) in item.filtersRuleList">
                                        <el-select @change="changeEventProperty(item.eventIndex,item1.selUserPropertyIndex,index,index1)" class="filter-item-button filter-item-mr" v-model="item1.selUserPropertyIndex" size="mini" placeholder="请选择">
                                            <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList" :label="item2.propertyName" :value="index2"></el-option>
                                        </el-select>
                                        <el-select @change="timeEventChange(index,index1)"  style="width: 100px" v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1" class="filter-item-button filter-item-mr" v-model="item1.selUserPropertyTimeIndex" size="mini" placeholder="请选择">
                                            <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].timeTypeItemList" :label="item2.name" :value="index2"></el-option>
                                        </el-select>

                                        <el-select v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1" style="width: 110px" class="filter-item-button filter-item-mr" v-model="item1.selUserPropertyOperateIndex" size="mini" placeholder="请选择">
                                            <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].operateTypeItemList.filter(time=>time.timeTypeId==(item1.selUserPropertyTimeIndex+1))" :label="item2.operateName" :value="index2"></el-option>
                                        </el-select>
                                        <el-select v-else style="width: 110px" class="filter-item-button filter-item-mr" v-model="item1.selUserPropertyOperateIndex" size="mini" placeholder="请选择">
                                            <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].operateTypeItemList" :label="item2.operateName" :value="index2"></el-option>
                                        </el-select>
                                        <template v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1">
                                            <template v-if="item1.selUserPropertyTimeIndex==0">
                                                <template v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].operateTypeItemList.filter(time=>(item1.selUserPropertyTimeIndex+1)==time.timeTypeId)[item1.selUserPropertyOperateIndex||0].moreParam==1">
                                                    <el-input-number size="mini" v-model="item1.selUserPropertyNumberValue1" controls-position="right" :min="0"></el-input-number>
                                                    ~
                                                    <el-input-number size="mini" v-model="item1.selUserPropertyNumberValue2" controls-position="right" :min="0"></el-input-number>
                                                </template>
                                                <el-input-number v-else size="mini" v-model="item1.selUserPropertyNumberValue" controls-position="right" :min="0"></el-input-number>
                                                <el-select style="width: 70px;margin-left: 10px" v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].properytDataType.indexOf('Date')>-1" class="filter-item-button filter-item-mr" v-model="item1.selUserPropertyOperateUnitValue" size="mini" placeholder="请选择">
                                                    <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].timeTypeItemList[item1.selUserPropertyTimeIndex].activeTimeItemList" :label="item2.name" :value="item2.value"></el-option>
                                                </el-select>
                                            </template>
                                            <template v-else>
                                                <el-date-picker
                                                    v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].operateTypeItemList.filter(time=>(item1.selUserPropertyTimeIndex+1)==time.timeTypeId)[item1.selUserPropertyOperateIndex||0].moreParam==1"
                                                    size="mini"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    v-model="item1.selUserPropertyTimeValue1"
                                                    type="datetimerange"
                                                    range-separator="至"
                                                    start-placeholder="开始日期"
                                                    end-placeholder="结束日期">
                                                </el-date-picker>
                                                <el-date-picker
                                                    v-else
                                                    size="mini"
                                                    value-format="yyyy-MM-dd HH:mm:ss"
                                                    v-model="item1.selUserPropertyTimeValue"
                                                    type="datetime"
                                                    placeholder="选择日期">
                                                </el-date-picker>
                                            </template>
                                        </template>
                                        <template v-else-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex].dicType==1">
                                            <el-select class=" filter-item-mr" multiple filterable reserve-keyword v-model="item1.selUserPropertyMulValue" size="mini" placeholder="可输入搜索">
                                                <el-option v-for="item2 in item1.selUserPropertyValueList" :label="item2.dicValue" :value="item2.dicKey"></el-option>
                                            </el-select>
                                        </template>
                                        <template v-else-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].properytDataType=='Number'">
                                            <template v-if="eventOptionList[item.eventIndex].conditionPropertyList[item1.selUserPropertyIndex||0].operateTypeItemList[item1.selUserPropertyOperateIndex].moreParam==1">
                                                <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item1.selUserPropertyNumberValue1" size="mini" type="Number"></el-input>
                                                ~
                                                <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item1.selUserPropertyNumberValue2" size="mini" type="Number"></el-input>
                                            </template>
                                            <el-input style="width: 100px" v-else class="value-input filter-item-mr" v-model="item1.selUserPropertyNumberValue" size="mini" type="Number"></el-input>
                                        </template>
                                        <template v-else>
                                            <el-input class="value-input filter-item-mr" v-model="item1.selUserPropertyStringValue" size="mini" type="text"></el-input>
                                        </template>
                                        <el-button style="color: red" icon="el-icon-close" size="mini" type="text" @click="delEventProperty(index,index1)"></el-button>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="filter-item-bottom filter-item-mb">-->
<!--                                <el-select class="filter-item-button filter-item-mr" v-model="item.selUserBottomPropertyIndex" size="mini" placeholder="请选择">-->
<!--                                    <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList" :label="item2.propertyName" :value="index2"></el-option>-->
<!--                                </el-select>-->
<!--                                <el-select v-if="eventOptionList[item.eventIndex].conditionPropertyList[item.selUserBottomPropertyIndex].funItemList.length>0" style="width: 100px" class="filter-item-button filter-item-mr" v-model="item.selUserBottomPropertyFunIndex" size="mini" placeholder="请选择">-->
<!--                                    <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item.selUserBottomPropertyIndex].funItemList" :label="item2.funName" :value="index2"></el-option>-->
<!--                                </el-select>-->
<!--                                <el-select style="width: 100px" class="filter-item-button filter-item-mr" v-model="item.selUserBottomPropertyOperateIndex" size="mini" placeholder="请选择">-->
<!--                                    <el-option v-for="(item2,index2) in eventOptionList[item.eventIndex].conditionPropertyList[item.selUserBottomPropertyIndex].operateTypeItemList" :label="item2.operateName" :value="index2"></el-option>-->
<!--                                </el-select>-->
<!--                                <template v-if="eventOptionList[item.eventIndex].conditionPropertyList[item.selUserBottomPropertyIndex].properytDataType=='Number'">-->
<!--                                    <template v-if="eventOptionList[item.eventIndex].conditionPropertyList[item.selUserBottomPropertyIndex||0].operateTypeItemList[item.selUserBottomPropertyOperateIndex].moreParam==1">-->
<!--                                        <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item.selUserBottomPropertyNumber1Value" size="mini" type="Number"></el-input>-->
<!--                                        ~-->
<!--                                        <el-input style="width: 100px" class="value-input filter-item-mr" v-model="item.selUserBottomPropertyNumber2Value" size="mini" type="Number"></el-input>-->
<!--                                    </template>-->
<!--                                    <el-input style="width: 100px" v-else class="value-input filter-item-mr" v-model="item.selUserBottomPropertyNumberValue" size="mini" type="Number"></el-input>-->

<!--                                </template>-->

<!--                                <el-input v-else class="value-input filter-item-mr" v-model="item.selUserBottomPropertyValue" size="mini" type="text"></el-input>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <div class="section-title"><i class="el-icon-s-open"></i>更新方式</div>
        <section class="section-content">
            <el-radio size="mini" v-model="updateState" label="1" border>手动更新</el-radio>
            <el-radio size="mini" v-model="updateState" label="2" disabled border>自动更新</el-radio>
        </section>
        <div class="section-title" ><el-tooltip class="item" effect="dark" content="去重会排除7天内有推送过用户" placement="right"><i class="el-icon-info"></i></el-tooltip>是否去重</div>
        <section class="section-content">
            <template>
                <el-radio size="mini" v-model="distinctStatus" :label="1" border>去重</el-radio>
                <el-radio size="mini" v-model="distinctStatus" :label="2"  border>不去重</el-radio>
            </template>
        </section>
        <div class="section-title"><i class="el-icon-s-custom"></i>预估人数</div>

        <section class="section-content" style="display: flex;align-items: center;height:42px;">
            <el-button size="mini" type="primary" round plain @click="getSceneCount">点击预估</el-button>
            <span v-if="showLoadingIcons" style="margin-left: 20px;font-weight: 600" class="el-icon-loading"></span>
            <span v-if="userAllCount!=undefined" style="margin-left: 20px;font-size: 30px">{{userAllCount}}</span>
        </section>
        <div class="section-title"><i class="el-icon-s-data"></i>筛选人数 <span style="font-size: 10px;color: #e50011">(*建议预估人数之后进行选择，为0代表不筛选)</span></div>
        <section class="section-content">
            <el-input-number size="mini" v-model="chooseCount" :min="0" placeholder="0 不限制"></el-input-number>
        </section>
        <section class="submit-save-btn">
            <el-button type="danger" size="medium" @click="submitSave">保存群组</el-button>
        </section>
    </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
export default {
    name: "groupManage",
    data(){
        return {
            //群组ID
            groupId:this.$route.params.id,
            groupName:'',
            remark:'',
            chooseCount:0,
            distinctStatus:2,
            userAllCount:undefined,
            showLoadingIcons:false,
            updateState:'1',
            formData:{
                state:'0',
                update:''
            },
            userPropertyList:[],//用户属性列表
            userActionList:[],//用户行为列表
            userOptionList:[],
            eventOptionList:[],
            valueOptionsList:[],
            ruleList:[],
            userEvent:[]
        }
    },
    components:{defaultSec},
    mounted() {
        this.initEventList()
    },
    methods:{
        initEventList(){
            this.$apiGet('lechun-cms/event/getEventCondtion',{}).then(res1 => {
                console.log(res1)
                this.userOptionList = res1.userEvent.conditionPropertyList
                this.userEventId = res1.userEvent.eventId
                this.eventOptionList = res1.conditionEventList
                if(this.groupId&&this.groupId!=0){//获取现有群组详情
                    this.$apiGet('lechun-cms/scene/getUserGroupDetail',{ groupId:this.groupId}).then(res => {
                        this.groupName = res.groupName
                        this.remark=res.remark
                        this.chooseCount=res.chooseCount
                        this.distinctStatus=res.distinctStatus
                        let userEvent = res.userEvent|| {filtersRuleList:[]}
                        //初始化用户属性
                        this.userPropertyList = userEvent.filtersRuleList.map((item,index)=>{
                            this.userOptionList.map((item1,index1)=>{
                                if(item.eventPropertyId == item1.eventPropertyId){
                                    item.selUserPropertyItem = item
                                    item.selUserPropertyIndex = index1
                                    //执行初始项来自字典下拉列表
                                    this.changeProperty(index1,index)
                                    //时间选择
                                    if(item1.properytDataType.indexOf('Date')>-1){
                                        (item1.timeTypeItemList||[]).map((item3,index3)=>{
                                            if(item.timeTypeId==item3.id){
                                                item.selUserPropertyTimeIndex = index3
                                                item.selUserPropertyTimeItem = item3
                                            }
                                        })
                                    }
                                    //操作选择
                                    let operateId = '';
                                    let operateFilterList = item1.operateTypeItemList||[]
                                    if(item1.properytDataType.indexOf('Date')>-1){
                                        operateFilterList = operateFilterList.filter(time=>{
                                            return time.timeTypeId==item.selUserPropertyTimeItem.id
                                        })
                                    }
                                    operateFilterList.map((item2,index2)=>{
                                        if(item.operateId==item2.operateId){
                                            operateId = item.operateId
                                            item.selUserPropertyOperateIndex = index2
                                            item.selUserPropertyOperateItem= item2
                                        }
                                    });

                                    //value选择
                                    if(item1.dicType==1){//选框
                                        item.selUserPropertyMulValue = []
                                        this.getValue(item.eventPropertyId).then(value=>{
                                            item.selUserPropertyValueList = value
                                            let selValue = item.params.split(',')
                                            value.map((item4,index4)=>{
                                                selValue.map(item5=>{
                                                    if(item5==item4.dicKey){
                                                        item.selUserPropertyMulValue.push(item5)
                                                    }
                                                })
                                            })
                                        })
                                    }else if(item1.properytDataType.indexOf('Date')>-1){//时间类型
                                        if(item.selUserPropertyOperateItem.moreParam==1){//介于之间
                                            let st = item.params.split(',')[0]
                                            let et = item.params.split(',')[1]

                                            if(item.timeTypeId==1){//相对时间
                                                let unit = item.params.split('-')[2]
                                                console.log(item.params,st,et,unit)
                                                st = st.split('-')[0]
                                                et = et.split('-')[0]
                                                item.selUserPropertyNumberValue1 = st
                                                item.selUserPropertyNumberValue2 = et
                                                item.selUserPropertyOperateUnitValue = unit
                                            }else{//绝对时间
                                                item.selUserPropertyTimeValue1 = [st,et]
                                            }
                                        }else{
                                            if(item.timeTypeId==1){//相对时间
                                                let value = item.params.split('-')[0]
                                                let unit = item.params.split('-')[1]
                                                console.log(item.params,value,unit)
                                                item.selUserPropertyNumberValue = value
                                                item.selUserPropertyOperateUnitValue = unit
                                            }else{//绝对时间
                                                item.selUserPropertyTimeValue = item.params
                                            }
                                        }
                                    }else if(item1.properytDataType=='Number'){
                                        if(item.selUserPropertyOperateItem.moreParam==1){
                                            let st = item.params.split(',')[0]
                                            let et = item.params.split(',')[1]
                                            item.selUserPropertyNumberValue1 = st
                                            item.selUserPropertyNumberValue2 = et
                                        }else{
                                            item.selUserPropertyNumberValue = item.params
                                        }

                                    }else{
                                        item.selUserPropertyStringValue = item.params
                                    }
                                }

                            })
                            return item
                        })
                        //初始化用户行为
                        let userGroupRule = res.userGroupRule||{ruleList:[]}
                        this.userActionList = userGroupRule.ruleList.map((item,index)=>{
                            this.eventOptionList.map((item1,index1)=>{
                                if(item.eventId == item1.eventId){
                                    item.eventIndex = index1
                                    item1.conditionPropertyList.map((item2,index2)=>{//事件option下的属性列表
                                        item.filtersRuleList = item.filtersRuleList.map((item3,index3)=>{//选中事件下的属性列表
                                            if(item3.eventPropertyId == item2.eventPropertyId){
                                                item3.selUserPropertyIndex = index2
                                                //执行初始项来自字典下拉列表
                                                this.changeEventProperty(index1,index2,index,index3)
                                                //属性时间选择
                                                if(item2.properytDataType.indexOf('Date')>-1){
                                                    (item2.timeTypeItemList||[]).map((item5,index5)=>{
                                                        if(item3.timeTypeId==item5.id){
                                                            item3.selUserPropertyTimeIndex = index5
                                                            item3.selUserPropertyTimeItem = item5
                                                        }
                                                    })
                                                }
                                                //属性操作选择
                                                let operateId = '';
                                                let operateFilterList = item2.operateTypeItemList||[]
                                                if(item2.properytDataType.indexOf('Date')>-1){
                                                    operateFilterList = operateFilterList.filter(time=>{
                                                        return time.timeTypeId==item3.selUserPropertyTimeItem.id
                                                    })
                                                }
                                                operateFilterList.map((item4,index4)=>{
                                                    if(item4.operateId==item3.operateId){
                                                        operateId = item4.operateId
                                                        item3.selUserPropertyOperateIndex = index4
                                                        item3.selUserPropertyOperateItem = item4
                                                    }
                                                });

                                                //value选择
                                                if(item2.dicType==1){//选框
                                                    item3.selUserPropertyMulValue = []
                                                    this.getValue(item2.eventPropertyId).then(value=>{
                                                        item3.selUserPropertyValueList = value
                                                        let selValue = item3.params.split(',')
                                                        value.map((item6,index6)=>{
                                                            selValue.map(item7=>{
                                                                if(item7==item6.dicKey){
                                                                    item3.selUserPropertyMulValue.push(item7)
                                                                }
                                                            })
                                                        })
                                                    })
                                                }else if(item2.properytDataType.indexOf('Date')>-1){//时间类型
                                                    if(item3.selUserPropertyOperateItem.moreParam==1){//介于之间
                                                        let st = item3.params.split(',')[0]
                                                        let et = item3.params.split(',')[1]
                                                        if(item3.timeTypeId==1){//相对时间
                                                            let unit = item3.params.split('-')[2]
                                                            st = st.split('-')[0]
                                                            et = et.split('-')[0]
                                                            item3.selUserPropertyOperateUnitValue = unit
                                                            item3.selUserPropertyNumberValue1 = st
                                                            item3.selUserPropertyNumberValue2 = et
                                                        }else{//绝对时间
                                                            item3.selUserPropertyTimeValue1 = [st,et]
                                                        }
                                                    }else{
                                                        if(item3.timeTypeId==1){//相对时间
                                                            let value = item3.params.split('-')[0]
                                                            let unit = item3.params.split('-')[1]
                                                            item3.selUserPropertyNumberValue = value
                                                            item3.selUserPropertyOperateUnitValue = unit

                                                        }else{//绝对时间
                                                            item3.selUserPropertyTimeValue = item3.params
                                                        }
                                                    }
                                                }else if(item2.properytDataType=='Number'){
                                                    if(item3.selUserPropertyOperateItem.moreParam==1){
                                                        let st = item3.params.split(',')[0]
                                                        let et = item3.params.split(',')[1]
                                                        item3.selUserPropertyNumberValue1 = st
                                                        item3.selUserPropertyNumberValue2 = et
                                                    }else{
                                                        item3.selUserPropertyNumberValue = item3.params
                                                    }
                                                }else{
                                                    item3.selUserPropertyStringValue = item3.params
                                                }
                                            }

                                            return item3
                                        })
                                        //底部属性次数选择
                                        if(item.measure&&item.measure.eventPropertyId==item2.eventPropertyId){
                                            item.selUserBottomPropertyIndex = index2;

                                            (item2.funItemList||[]).map((item8,index8)=>{
                                                if(item.measure.funId==item8.funId){
                                                    item.selUserBottomPropertyFunIndex = index8
                                                }
                                            });
                                            let operateBottomId = '';
                                            (item2.operateTypeItemList||[]).map((item9,index9)=>{
                                                if(item.measure.operateId==item9.operateId){
                                                    operateBottomId = item9.operateId
                                                    item.selUserBottomPropertyOperateIndex = index9
                                                }
                                            });
                                            if(item2.properytDataType=='Number'){
                                                if(operateBottomId==6){
                                                    let st = item.measure.params.split(',')[0]
                                                    let et = item.measure.params.split(',')[1]
                                                    item.selUserBottomPropertyNumber1Value = st
                                                    item.selUserBottomPropertyNumber2Value = et
                                                }else{
                                                    item.selUserBottomPropertyNumberValue = item.measure.params
                                                }
                                            }else{
                                                item.selUserBottomPropertyValue = item.measure.params
                                            }
                                        }

                                    })
                                }
                            })
                            return item
                        })
                    })
                }
            })
        },
        changeProperty(selIndex,index){
            let property = this.userOptionList[selIndex]
            let id = property.eventPropertyId
            let type = property.dicType
            if(type == 1){
                this.getValue(id).then(res=>{
                    console.log(res)
                    this.userPropertyList[index].selUserPropertyValueList = res
                })
            }
        },
        timeUserChange(index){
            this.userPropertyList[index].selUserPropertyOperateIndex = 0
        },
        changeEvent(index){
            console.log(index)
            this.userActionList[index].filtersRuleList = []

        },
        changeEventProperty(selIndex,selIndex1,index,index1){
            let property = this.eventOptionList[selIndex].conditionPropertyList[selIndex1]
            let id = property.eventPropertyId
            let type = property.dicType
            console.log('pro',property,id,type)
            if(type==1){
                this.getValue(id).then(res=>{
                    this.userActionList[index].filtersRuleList[index1].selUserPropertyValueList = res
                })
            }

        },
        timeEventChange(index,index1){
            this.userActionList[index].filtersRuleList[index1].selUserPropertyOperateIndex = 0

        },

        getValue(id){
            return new Promise(resolve => {
                this.$apiGet('lechun-cms/scrmproperty/getEventPropertyItemList',{ dicId:id}).then(res => {
                    this.valueOptionsList = res
                    resolve(res)
                })
            })

        },
        addNewUserProperty(){
            this.userPropertyList.push({
                selUserPropertyIndex:0,
                selUserPropertyOperateIndex:0,
                selUserPropertyOperateUnitValue:'',
                selUserPropertyTimeIndex:0,
                selUserPropertyNumberValue:0,
                selUserPropertyNumberValue1:0,
                selUserPropertyNumberValue2:0,
                selUserPropertyTimeValue:'',
                selUserPropertyTimeValue1:[],
                selUserPropertyMulValue:[],
                selUserPropertyValueList:[],
                selUserPropertyStringValue:'',
            })
            //执行初始项来自字典下拉列表
            this.changeProperty(0,this.userPropertyList.length-1)
        },
        delUserProperty(index){
            this.userPropertyList.splice(index,1)
        },
        addNewAction(){
            this.userActionList.push({
                eventIndex:0,
                selUserBottomPropertyIndex:0,
                selUserBottomPropertyOperateIndex:0,
                selUserBottomPropertyFunIndex:0,
                selUserBottomPropertyValue:'',
                selUserBottomPropertyNumberValue:'',
                selUserBottomPropertyNumber1Value:'',
                selUserBottomPropertyNumber2Value:'',
                filtersRuleList:[],
            })
        },
        addNewActionProperty(index,selEventIndex){
            this.userActionList[index].filtersRuleList.push(
                {
                    selUserPropertyIndex:0,
                    selUserPropertyOperateIndex:0,
                    selUserPropertyOperateUnitValue:'',
                    selUserPropertyTimeIndex:0,
                    selUserPropertyNumberValue:0,
                    selUserPropertyNumberValue1:0,
                    selUserPropertyNumberValue2:0,
                    selUserPropertyTimeValue:'',
                    selUserPropertyTimeValue1:[],
                    selUserPropertyMulValue:[],
                    selUserPropertyValueList:[],
                    selUserPropertyStringValue:'',
                }
            )
            //执行初始项来自字典下拉列表
            this.changeEventProperty(selEventIndex,0,index,this.userActionList[index].filtersRuleList.length-1)

        },
        delEvent(index){
            this.userActionList.splice(index,1)
        },
        delEventProperty(index,index1){
            this.userActionList[index].filtersRuleList.splice(index1,1)
        },

        createCellPos( n ){
            var ordA = 'A'.charCodeAt(0);
            var ordZ = 'Z'.charCodeAt(0);
            var len = ordZ - ordA + 1;
            var s = "";
            while( n >= 0 ) {

                s = String.fromCharCode(n % len + ordA) + s;

                n = Math.floor(n / len) - 1;

            }
            return s;
        },
        /*设置保存参数*/
        setSaveParam(){
            console.log('ac',this.userActionList)
            console.log('pr',this.userPropertyList)
            //用户属性保存
            let userSaveList = this.userPropertyList.map(item=>{
                let optionItem = this.userOptionList[item.selUserPropertyIndex];
                let dicType = optionItem.dicType
                let params = ''
                let operateFilterList = optionItem.operateTypeItemList?optionItem.operateTypeItemList:[]
                let timeTypeId = optionItem.timeTypeItemList?optionItem.timeTypeItemList[item.selUserPropertyTimeIndex].id:''
                if(optionItem.timeTypeItemList){
                    operateFilterList = operateFilterList.filter(time=>{
                        return timeTypeId == time.timeTypeId
                    })
                }
                let operateId = operateFilterList?operateFilterList[item.selUserPropertyOperateIndex].operateId:''
                let operateMoreParam = operateFilterList?operateFilterList[item.selUserPropertyOperateIndex].moreParam:''

                console.log(operateFilterList)
                if(dicType==1){//多选框
                    params = item.selUserPropertyMulValue.join(',')
                }else if(optionItem.timeTypeItemList){//时间选框
                    if(operateMoreParam==1){//介于
                        if(timeTypeId==1){//相对时间
                            params = item.selUserPropertyNumberValue1+'-'+item.selUserPropertyOperateUnitValue+','+item.selUserPropertyNumberValue2+'-'+item.selUserPropertyOperateUnitValue
                        }else{//绝对时间
                            params = item.selUserPropertyTimeValue1.join(',')
                        }
                    }else{
                        if(timeTypeId==1){//相对时间
                            params = item.selUserPropertyNumberValue+'-'+item.selUserPropertyOperateUnitValue
                        }else{//绝对时间
                            params = item.selUserPropertyTimeValue
                        }
                    }
                }else if(optionItem.properytDataType=='Number'){
                    if(operateMoreParam==1){
                        params = item.selUserPropertyNumberValue1+','+item.selUserPropertyNumberValue2
                    }else{
                        params = item.selUserPropertyNumberValue
                    }
                }else{
                    params = item.selUserPropertyStringValue
                }
                item = {
                    relation: "and",
                    type: "property_rule",
                    eventPropertyId:optionItem.eventPropertyId,
                    operateId,
                    timeTypeId,
                    params
                }
                return item

            })
            console.log(userSaveList)
            //用户行为保存
            let userActionSave = this.userActionList.map(item=>{
                let propertyList = this.eventOptionList[item.eventIndex].conditionPropertyList
                let buttonOption = propertyList[item.selUserBottomPropertyIndex]
                let filtersList = item.filtersRuleList
                console.log(buttonOption.operateTypeItemList)
                let buttonParams = ''
                if(buttonOption.properytDataType=='Number'){
                    if(buttonOption.operateTypeItemList[item.selUserBottomPropertyOperateIndex].operateId==6){
                        buttonParams = item.selUserBottomPropertyNumber1Value+','+item.selUserBottomPropertyNumber2Value
                    }else{
                        buttonParams = item.selUserBottomPropertyNumberValue
                    }
                }else{
                    buttonParams = item.selUserBottomPropertyValue
                }
                item = {
                    eventId: this.eventOptionList[item.eventIndex].eventId,
                    type: "property_rule",
                    relation: "and",
                    filtersRuleList: filtersList.map(item1=>{
                        let optionItem = propertyList[item1.selUserPropertyIndex]
                        let dicType = optionItem.dicType
                        let operateFilterList = optionItem.operateTypeItemList?optionItem.operateTypeItemList:[]
                        let timeTypeId = optionItem.timeTypeItemList?optionItem.timeTypeItemList[item1.selUserPropertyTimeIndex].id:''
                        let params = ''
                        if(optionItem.timeTypeItemList){
                            operateFilterList = operateFilterList.filter(time=>{
                                return timeTypeId == time.timeTypeId
                            })
                        }
                        let operateId = operateFilterList?operateFilterList[item1.selUserPropertyOperateIndex].operateId:''
                        let operateMoreParam = operateFilterList?operateFilterList[item1.selUserPropertyOperateIndex].moreParam:''

                        // let operateId = optionItem.operateTypeItemList?optionItem.operateTypeItemList[item1.selUserPropertyOperateIndex].operateId:''

                        if(dicType==1){//多选框
                            params = item1.selUserPropertyMulValue.join(',')
                        }else if(optionItem.timeTypeItemList){//时间选框
                            if(operateMoreParam==1){//介于
                                if(timeTypeId==1){//相对时间
                                    params = item1.selUserPropertyNumberValue1+'-'+item1.selUserPropertyOperateUnitValue+','+item1.selUserPropertyNumberValue2+'-'+item1.selUserPropertyOperateUnitValue
                                }else{//绝对时间
                                    params = item1.selUserPropertyTimeValue1.join(',')
                                }
                            }else{
                                if(timeTypeId==1){//相对时间
                                    params = item1.selUserPropertyNumberValue+'-'+item1.selUserPropertyOperateUnitValue
                                }else{//绝对时间
                                    params = item1.selUserPropertyTimeValue
                                }
                            }
                        }else if(optionItem.properytDataType=='Number'){
                            if(operateMoreParam==1){
                                params = item1.selUserPropertyNumberValue1+','+item1.selUserPropertyNumberValue2
                            }else{
                                params = item1.selUserPropertyNumberValue
                            }
                        }else{
                            params = item1.selUserPropertyStringValue
                        }
                        item1 = {
                            eventPropertyId: optionItem.eventPropertyId,
                            operateId,
                            params,
                            relation: "and",
                            timeTypeId,
                            type: "property_rule"
                        }
                        return item1
                    }),
                    measure:{
                        eventPropertyId:buttonOption.eventPropertyId,
                        funId:buttonOption.funItemList.length>0?buttonOption.funItemList[item.selUserBottomPropertyFunIndex].funId:'',
                        operateId: buttonOption.operateTypeItemList[item.selUserBottomPropertyOperateIndex].operateId,
                        params:buttonParams,
                        type:'property_rule'
                    }
                }
                return item
            })
            let formParam = {
                groupName:this.groupName,
                remark:this.remark,
                chooseCount:this.chooseCount,
                distinctStatus:this.distinctStatus,
                groupId:parseInt(this.groupId)||'',
                userGroupRule:{
                    relation:'and',
                    ruleType:'event_rule',
                    ruleList:userActionSave
                },
                userEvent:{
                    eventId:this.userEventId,
                    filtersRuleList:userSaveList,
                    relation: "and"
                }
            }
            console.log('保存',formParam)
            return formParam
        },
        /*预估人数*/
        getSceneCount(){
            this.showLoadingIcons = true
            this.$apiPost('lechun-cms/scene/getUserCount',this.setSaveParam()).then(res=>{
                console.log(res)
                let _this = this
                this.userAllCount = res
                this.showLoadingIcons = false
            })
        },
        submitSave(){
            this.$apiPost('lechun-cms/scene/saveUserGroup',this.setSaveParam()).then(res=>{
                console.log(res)
                let _this = this
                this.$alert('', '保存成功', {
                    confirmButtonText: '确定',
                    center: true,type:'success',
                    callback: action => {
                        _this.$router.push({path: '/sceneList', name: 'sceneList'})

                    }
                });
            })
        },
    }
}
</script>

<style  lang="scss" rel="stylesheet/scss">

.group-manage-detail-wrap {
    .section-title {
        font-size: 14px;
        line-height: 30px;
        border-bottom: 1px solid #dfdfdf;

        i {
            color: #209e91;
            margin-right: 6px;
        }
    }

    .section-content {
        padding: 10px;
    }

    .filter-wrap {
        border: 1px solid #c8c8c8;
        border-radius: 3px;
        margin-bottom: 10px;
        .value-input{
            width: 200px;
        }
        .filter-title {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            p {
                font-weight: 600;
            }
        }

        .line-wrap {
            margin-right: 10px;
            padding: 14px 0;

            p {
                height: 100%;
                box-sizing: border-box;
                border-right: 2px solid #209e91;
                font-size: 12px;
                display: flex;
                align-items: center;
                margin: 0;
            }
        }

        .filter-content {
            padding: 10px 20px 10px 20px;
            display: flex;
        }

        .filter-item {
            position: relative;
            padding-bottom: 20px;
        }

        .filter-item-mr {
            margin-right: 10px;
        }

        .filter-item-mb {
            margin-bottom: 10px;
        }

        .filter-item-button {
            .el-input__inner {
                background: #209e91;
                color: #ffffff;
                border: none;
            }
            .el-select .el-input .el-select__caret{
                color: #ffffff;
            }
        }

        .filter-index {
            width: 20px;
            height: 20px;
            display: inline-block;
            font-size: 10px;
            border-radius: 20px;
            background: #55a532;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            font-weight: 600;
        }

        .filter-item-mid {
            display: flex;
            padding-left: 60px;

            .mid-left {
                border-right: 2px dashed #55a532;
                font-size: 10px;
                margin-right: 20px;
                padding-right: 3px;
            }

            .mid-mid {
                padding-top: 28px;
            }

            .mid-right {
                padding-top: 20px;
            }

        }

        .filter-item-bottom {
            padding-left: 30px;
        }
    }
    .submit-save-btn{
        display: flex;
        justify-content: flex-end;
    }
}
</style>